.App {
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}

body {
  background-color: #FF8978;
}

a {
  text-decoration: underline;
  color: white;
}

.text-style {
  color: white;
  font-size: 1.5rem;
}

.store-logo {
  width: 80%;
  max-width: 400px;
  text-align: center;
  margin: auto;
  margin-top: 3rem;
  display: block;
}
.store-logo-ios {
  width: 70%;
  max-width: 350px;
  margin: auto;
  margin-top: 3rem;
  display: block;
}
.nanea-logo-container {
  background-color: white;
  text-align: center;
  width: 100%;
}

.nanea-logo {
  width: 60%;
  max-width: 600px;
  padding: 2rem;
}
